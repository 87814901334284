// import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import logo from './Image/verint_logo.svg';
import { useForm } from "react-hook-form";
import { Label, Popup, LuxStylesProvider } from '@verint/shared-react-components';
import './RequestForm.css';
import '@verint/lux_icon_font/verint_lux.css';
// Components Imports
import Header from './Components/Header';
import SubmitButton from './Components/SubmitButton';
import Environment from './Components/Environment';
import TimeZone from './Components/TimeZonex';
import LabDurationLabel from './Components/LabDurationLabel.jsx'
import StartDate from './Components/StartDate';
import EndDate from './Components/EndDate';
import Reason from './Components/Reason';
import InternalId from './Components/InternalId';
import DesktopRequired from './Components/DesktopRequired';
import Billable from './Components/Billable';
import PodNumber from './Components/PodNumber';
import MobileGatewayCheckbox from './Components/MobileGatewayCheckbox';
import AdditionalVcorpUsers from './Components/AdditionalVcorpUsers.jsx';
import RequestAnythingCheckbox from './Components/RequestAnything.jsx';
import CurrentTime from './Components/CurrentTime';
//import TextAnalytics from './Components/TextAnalytics';
import LiveRecordingCheckbox from './Components/LiveRecordingCheckbox';
import LMSCheckbox from './Components/LMSCheckbox';
import GivenTime from './Components/GivenTime';
import VTACheckbox from './Components/VTACheckbox';
import MSTeamsAzureCheckbox from "./Components/MSTeamsAzureCheckbox";
import OperationManagerCheckbox from "./Components/OMWFECheckbox";
import FlexManagerCheckbox from './Components/FlexManagerCheckBox';
import FriendlyURL from './Components/FriendlyURL';
import EmployeeTimecollector from './Components/EmployeeTimeCollector';
import ScheduleHistoryCheckbox from './Components/ScheduleHistory';
import UrlName from './Components/UrlName';
import TandCCheckbox from './Components/T&CCheckbox';
import S3BuketCheckbox from './Components/S3BucketCheckBox';
import DaVinciCheckBox from './Components/DaVinciCheckBox';
import AWSLiveRecordingCheckbox from './Components/AwsLiveRecording';
import CloudWFODemoCheckbox from "./Components/CloudWFODemoCheckbox";
import CommunityCheckbox from './Components/CommunityCheckbox';
import EmailAddress from './Components/EmailAddress';
import ForwardEmail from './Components/ForwardEmail';
import Region from './Components/Region';
import LuxCheckbox from './Components/LuxUl';
import IvaProCheckbox from './Components/IvaProCheckbox';
import CurrentRequestHome from './currentRequest/Components/home.jsx';
import $ from 'jquery';
import TimeFlex from './Components/Timeflex.jsx';
import KMAutomationCheckbox from './Components/KMAutomatiobCheckbox.jsx';
function App() {
    

    let environemntRecords = []

    // State of the Environmnet component, will be used to determine structure of the form
    const [group, setGroup] = useState(null)
    const [config, setConfig] = useState(null)
    const [environmentSelected, setEnvironmentSelected] = useState(null)
    const [regionSelected, setRegionSelected] = useState(null)
    const [environemntData, setEnvironmentData] = useState([])
    const [lmsSelected, setLmsSelected] = useState(null)
    const [urlchecked, seturlChecked] = useState(null);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [token, setToken] = useState(0)
    const [TandCSelected, setTandCSelected] = useState(null)
    const [communitySelected, setcommunitySelected] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [awsliveRecording, setAwsliveRecording] = useState(null)
    const [Luxui, setLuxui] = useState(null)
    const [liveRecording, setLiveRecording] = useState(null)
    const [ScheduleHistory , setScheduleHistory] = useState('')
    const [RequestAnything , setRequestAnything] = useState('')
    const [timeFlex, setTimeFlex] = useState(false)
    const [ValidationError, setValidationError]= useState('')
    const [ivapro, setIvapro] = useState(null)
    const [empTime, setEmpTime] = useState(null)
    const [KMAutomation, setKMAutomation] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [forwardEmail,setForwardEmail] = useState(null)
    const { handleSubmit} = useForm();
    const [friendly_tr , setFriendly_tr ] = useState(false)
    const [email_tr , setEmail_tr ] = useState(false)
    const formRef = useRef(null);
    const adminEmailUsers = getAdminName(); 
    let is_superAdmin = false
    const superAdmins = get_super_admins(); 
    // User to store labquota of user 
    const [userLabQuota, setUserLabQuota] = useState(-1)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    //function to hide the tr tag of friendly url
    const handleFriendlyURLVisibilityChange = (shouldHide) => {
        setFriendly_tr(shouldHide);
    };
    //function to hide the tr tag of additionmal recipient
    const handleEmailVisibilityChange = (shouldHide) => {
        setEmail_tr(shouldHide);
    };
    // set the display to none if friendly url is false 
    const style_tr = friendly_tr ? { display: "none" } : { display: "" };
    //set the display to none if additional recipient is false
    const emailstyle_tr = email_tr ? { display: "none" } : { display: "" };

    function OpenChatWindow(event){
       
        var url = window.location.href;
        var token = url.split('#')[1];
        window.location.href = "/form#" + token + "";
        window.open('https://verintdemo.atlassian.net/servicedesk/customer/portal/3', "_blank" ,'width=600, height=550')
        event.preventDefault();
        event.stopPropagation();
    }
    function get_token(){

        var url = window.location.href;
        var tokensString = url.split("#")[1];
        var startOfIdToken = tokensString.indexOf('=') + 1;
        var endOfIdToken = tokensString.indexOf('&');
        var token = tokensString.substring(startOfIdToken, endOfIdToken);
        return token
      }

    // function to fetch data from the DB in order to display the template names
    const fetchEnironemntRows = async (thisGroupName) => {
        var environmentDisplay = []
        var tokenvalue = get_token();  
        const response = await fetch(`/template/items?&token=${tokenvalue}`);
        //local testing
        //const response = await fetch(`http://localhost:3004/template/items?token=${tokenvalue}`);
        // console.log(response)
        const jsonData = await response.json();
        
        environemntRecords = jsonData
        setEnvironmentData(environemntRecords)
      }
    
    // function to fetch the admin names for the template administration page
    function getAdminName()
    {
        Adminjson = []            
        for( var i=0; i< environemntData.Count; i++)
        {
            if(environemntData.Items[i].template_name == "admin_emails_config"){
                var TemplateAdmin = environemntData.Items[i].template_admin
                var Adminjson=JSON.stringify(TemplateAdmin)
                console.log("The Template admins are ::::", Adminjson)
                break;  
            }
        }
        return Adminjson;
    }

    // function to fetch the superadmins for current request 
    function get_super_admins()
    {
        superAdmins_json = []             
        for( var i=0; i< environemntData.Count; i++)
        { 
        if(environemntData.Items[i].template_name == "admin_emails_config"){
            var superAdmins = environemntData.Items[i].currentRequests_disableSchedule_superAdmins
            var superAdmins_json=JSON.stringify(superAdmins)
            break;  
        }
        }
        return superAdmins_json;
    }
    if(userEmail != null &&  superAdmins.includes(userEmail.toLowerCase()) ){
        is_superAdmin = true
       
    }

    const handleFormSubmit = (e) => {
        //e.preventDefault();
        if (urlchecked == true) {
            if ( ValidationError != 'error' ) {
                // handle form submission 
                    const answer = window.confirm("are you sure you want to create a LAB?");
                    if (answer) {
                    // Save it!
                    formRef.current.submit();
                    console.log("Lab was created.");
                    } else {
                    // Do nothing!
                    console.log("Lab was not submitted ");
                    }
                  
                }
                else{
                    // show the alert and prevent page from submission
                    setShowAlert(true);
                    e.preventDefault();  
                    
                }
        }
       
      };

      const onSubmit  = (data, event) => {   
        //check if the Friendly URl is checked
         if (urlchecked == true) {
           //check if the shortname entered is available 
               if ( ValidationError != 'error' ) {
               // handle form submission 
                   formRef.current.submit();
               }
               else{
                   // show the alert and prevent page from submission
                   setShowAlert(true);
                   event.preventDefault();  
                   
               }
        }

        else{
               // submit the form 
               formRef.current.submit();
                
            }
       }
    
    // function to reset the value of the form when its closed
    const form_close = () =>{
        setAnchorEl(null);
        setEnvironmentSelected(null);
    }

    const get_react_config = async () => {
        const response = await fetch(`/config`);
        //local testing
        //const response = await fetch(`http://localhost:3004/config`);
        const jsonData = await response.json();
        setConfig(jsonData)
       
        ParseToken(jsonData)
        
       }

    // Get Lab Quota of a user
    const getLabQuotaForUser = async (userEmail) => {
        const command="cognito_users_lab_quota";
        let userlabQuota =0;
        const options = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: `{"token":"${token}", "command": "${command}", "username" : "${userEmail}"}`,
        };
        const request = new Request("/UserLabQuota", options);
        try {
            const response = await fetch(request);
            console.log("response", response);
            if (response.status === 200) {
                userlabQuota = await response.json();
            // teardown_data = await response.json();
            console.log("User labQuota :", userlabQuota); // This should now log the actual data
            } else {
            console.error("Error: ", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setUserLabQuota(userlabQuota);
    };


    useEffect(() => {        
        get_react_config(); 
        if(config != undefined){
            var tokenJson = ParseToken(config);
            var group = tokenJson["cognito:groups"][0];
            setGroup(group)
            var userEmail = tokenJson["cognito:username"];
            setUserEmail(userEmail)
            setGroup(group);
            setUserEmail(userEmail);
            fetchEnironemntRows(group);
            // Get Lab Quota of user 
            getLabQuotaForUser(userEmail);
            console.log(`INFO | group: ${group} user_email: ${userEmail}`)
        }
        else{
            console.log("Not able to get config")
        }
        

    },[token], [group],[regionSelected],[config])

    return (
        <div>
           
            <div className="d-none d-sm-block">
                <div className="row page_header">
                    <Header title="Verint Lab Request form" imgSrc={logo} token = {token} IsAdmin= {(adminEmailUsers.includes(userEmail)===true || adminEmailUsers.includes(userEmail)==="false") ? true:false} useremail={userEmail}/>
                </div>
            </div>
            <div className="d-sm-none">
                <div className="page_header_small">Verint Lab Request form</div>
            </div>

            <div className="container">            
                <div>
           
           
            <Label                  
                    className="verint-ws-label"
                    data-testid="verint-label"

                   >
                    Lab Requests
            </Label> 

            <br></br>
            {userLabQuota <= 0 ? (
                userLabQuota==-1 ? <div></div> :
                        <Label className="verint-error-label">"You have exceeded your lab quota, please clean up any unused labs you have. If you have justification to increase your quota limit please raise a ticket using this
                            &nbsp;<a
                            href="#"
                            onClick={(event) => {OpenChatWindow(event); }   }                            
                            >
                            link  
                            </a>&nbsp;." 
                        </Label>
            ) : ( 
           <Label
                    className="verint-regular-label"
                    data-testid="verint-label"
                    onClick={(event) => {setAnchorEl(event.currentTarget); }}
                    >
                    + Add Request
            </Label>   )
            }     
          <div className="jss182">

          <Popup        
                alertText="New Lab Request"  
                handleClose={form_close}
                CloseIconButtonProps={{
                    'aria-label': 'close'
                }}
                closeTooltipText=""
                size="large"  title="Lab Requests"  type="basic"  anchorEl = {anchorEl}  open={open} >

                <form id="requestform" method="POST" data-toggle="validator" encType="application/x-www-form-urlencoded" action={"/submit"} ref={formRef} onSubmit={handleFormSubmit} handleConfirm={handleSubmit(onSubmit)}
                    autoComplete="off" style={{ position: 'relative', margin: '0px 0px 24px', }}                    
                >
                    <div><Label className="verint-ws-label" data-testid="verint-label" style={{paddingRight:"38%",paddingBottom :"3%"}} > New Lab Request</Label></div>
                    {/* <Region group={group} userEmail={userEmail} environemntRecords={environemntData} regionSelected={regionSelected}  setRegionSelected={setRegionSelected}></Region> */}
                    <div>
                    {/* table to display all the components of requestform */}
                    <table className='reqform_table'>
                            <Environment group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} setEnvironmentSelected={setEnvironmentSelected} regionSelected={regionSelected} />
                                {((environmentSelected == "93") == true) || (ivapro !== true) && <TimeZone group={group} /> }  
                                <tr className='reqForm_tablerow'>
                                    <td className='td_row_leftvalue'>
                                        <LabDurationLabel></LabDurationLabel>                               
                                    </td>
                                    <td className='td_row_rightvalue dateSpace'>
                                        <StartDate startDate={startDate} setStartDate={setStartDate} endDate={endDate}/>
                                        <EndDate is_superAdmin={is_superAdmin} endDate={endDate} setEndDate={setEndDate} startDate={startDate}/>  
                                    </td>
                                </tr>
                                <Reason group={group}/>
                                <Billable group={group}/>
                                <InternalId group={group}/>
                                <CurrentTime /> 
                                <div style={{display:'none'}}><MobileGatewayCheckbox  group={group} environemntRecords={environemntData} environmentSelected={environmentSelected}/></div>     
                                <div style={{display:'none'}}><CloudWFODemoCheckbox  environmentSelected={environmentSelected} group={group} environemntRecords={environemntData} ></CloudWFODemoCheckbox></div>                   
                                <DesktopRequired environmentSelected = {environmentSelected} environemntRecords={environemntData} group={group}/>    
                                <AdditionalVcorpUsers environmentSelected={environmentSelected} environemntRecords={environemntData} group={group}/>   
                                <PodNumber environmentSelected={environmentSelected} group={group} environemntRecords={environemntData} /> 
                                <tr className='reqForm_tablerow' style= {style_tr}>
                                    <FriendlyURL environmentSelected={environmentSelected} onFriendlyURLVisibilityChange={handleFriendlyURLVisibilityChange}
                                         urlchecked ={urlchecked} seturlChecked={seturlChecked} 
                                        environemntRecords={environemntData} group ={group}/>
                                    {(environmentSelected != null) && 
                                    <UrlName urlchecked={urlchecked} ValidationError= {ValidationError}  setValidationError = {setValidationError} showAlert={showAlert} token = {token}></UrlName> } 
                                </tr> 
                                <tr className='reqForm_tablerow' style= {emailstyle_tr} >                             
                                        <ForwardEmail environmentSelected={environmentSelected} onForwardEmailVisibilityChange = {handleEmailVisibilityChange} environemntRecords={environemntData} group={group} forwardEmail={forwardEmail} setForwardEmail={setForwardEmail}/>
                                        {(environmentSelected != null) && <td className='td_row_rightvalue'><EmailAddress forwardEmail={forwardEmail}/>  </td>}                            
                                </tr>
                                <TandCCheckbox group={group}  environemntRecords={environemntData} environmentSelected={environmentSelected} TandCSelected={TandCSelected} setTandCSelected={setTandCSelected}></TandCCheckbox>
                                <VTACheckbox group={group} environemntRecords={environemntData} userEmail={userEmail} environmentSelected={environmentSelected}/>
                                <OperationManagerCheckbox environmentSelected={environmentSelected} group={group} environemntRecords={environemntData} ></OperationManagerCheckbox>
                                <TimeFlex group={group}  environemntRecords={environemntData} environmentSelected={environmentSelected} timeFlex={timeFlex} setTimeFlex={setTimeFlex}/>
                                <ScheduleHistoryCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} ScheduleHistory={ScheduleHistory} setScheduleHistory = {setScheduleHistory}/>
                                <RequestAnythingCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} RequestAnything={RequestAnything} setRequestAnything = {setRequestAnything}/>
                                <tr className='app_page'> 
                                    <td className='td_reason'>
                                        <LMSCheckbox environmentSelected={environmentSelected} group={group} environemntRecords={environemntData} lmsSelected={lmsSelected} setLmsSelected={setLmsSelected} userEmail={userEmail}/> 
                                        <GivenTime lmsSelected={lmsSelected} />
                                    </td>
                                </tr>
                                <FlexManagerCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected}  />
                                <LiveRecordingCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} liveRecording = {liveRecording} setLiveRecording = {setLiveRecording} awsliveRecording ={awsliveRecording}/>
                                <AWSLiveRecordingCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} awsliveRecording ={awsliveRecording} setAwsliveRecording={setAwsliveRecording} liveRecording = {liveRecording} />
                                <tr style={{border:'none'}}> <DaVinciCheckBox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} empTime={empTime}></DaVinciCheckBox></tr> 
                                <MSTeamsAzureCheckbox environmentSelected={environmentSelected} ></MSTeamsAzureCheckbox>
                                <EmployeeTimecollector group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} empTime={empTime} setEmpTime={setEmpTime}/>
                                <S3BuketCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected}></S3BuketCheckbox>
                                <KMAutomationCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} KMAutomation={KMAutomation} setKMAutomation={setKMAutomation} />
                                <CommunityCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} communitySelected={communitySelected} setcommunitySelected={setcommunitySelected}/>       
                                <LuxCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} Luxui ={Luxui} setLuxui={setLuxui}/>
                                <IvaProCheckbox group={group} environemntRecords={environemntData} environmentSelected={environmentSelected} ivapro={ivapro} setIvapro={setIvapro} />
                                <SubmitButton/>
                                <tr>
                                    <td>
                                        <div className="row">
                                            <div className="col-sm-6 md-6 lg-6">
                                                <input type="string" id="token" min="0" name="token" value={token} style={{ visibility: 'hidden' }} />
                                            </div>
                                        </div>
                            </td></tr>
                    </table>
                    </div>
                    
                </form>
                </Popup>
                    
                
            </div>
            </div>
            <div><CurrentRequestHome></CurrentRequestHome></div>
            </div>
            </div>
    );

// _____________________________________________________________________Parse Token

// function for validationg the token of the user 
function ParseToken(react_auth_url) {

            const configData =react_auth_url 
            var authURL = configData.REACT_APP_AUTH_URL
            var url = window.location.href;
            if(authURL ===undefined )
            {
                console.log("Authentication URL not found in environment variable")
                return;
            }
            if (url.split("#").length <= 1)
            {
                console.log("iD token not passed in url")
                window.location.href = authURL;
            }
            var tokensString = url.split("#")[1];
    
            // If a token string was not found redirct to cognito login
            if (tokensString === undefined) {
                window.location.href = authURL;
            }
    
            var startOfIdToken = tokensString.indexOf('=') + 1;
            var endOfIdToken = tokensString.indexOf('&');
            var token = tokensString.substring(startOfIdToken, endOfIdToken);
           
            setToken(token);

            // If the token variable is empty redirct to cognito login
            if (token === '') {
                window.location.href = authURL;
            }
    
            // Spliting the token into its section
            var sections = token.split('.');
            var tokenPayload = atob(sections[1]);
    
            // Check if the token is expired, if so return the user to cognito login
            var current_ts = Math.floor(new Date() / 1000);
            try {
                console.log("Token time exp: " + JSON.parse(tokenPayload).exp + "\nCurrent Time: " + current_ts);
                if (current_ts > JSON.parse(tokenPayload).exp) {
                    window.location.href = authURL;
                }
            } catch (e) {
                window.location.href = authURL;
            }
    
            // Parse tokenPayload in json object, if error redirect to cognito login
            try { var jsonPayload = JSON.parse(tokenPayload); } catch (e) { window.location.href = authURL; }
            // This will change the dropdown box options based on the cognito groups that the user is apart of
    
            return jsonPayload;
            // })

      
       
    }     

  
    function SystemStatusRedirect() {
        var url = window.location.href;
        var token = url.split('#')[1];
        var hostname = window.location.hostname;
        window.location.href = "/system_information#" + token + "";
       

    }
    function AdministrationRedirect() {
        var url = window.location.href;
        var token = url.split('#')[1];
        var hostname = window.location.hostname;
        window.location.href =  "/template-admin#" + token + "";
    }
    function CurrentRequestsRedirect() {
        // Getting parsed json data from token 
        var tokenJson = ParseToken(config);
        var username = tokenJson["cognito:username"];

        // Passing all token data to /requests-table
        var url = window.location.href;
        var token = url.split('#')[1];
        var hostname = window.location.hostname;
        window.location.href = "/requests-table?username=" + username + "#" + token + "";
    }

}
    
export default App;